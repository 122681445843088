import { getRoute, PATH } from '@/utils/routes';
import router from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { FilledUserIcon } from '@/assets/svgExports/FilledUserIcon';
import { WhatsappIcon } from '@/assets/svgExports/WhatsAppIcon2';
import { FilledShoppingCart } from '@/assets/svgExports/FilledShoppingCart';
import { SearchIcon } from '@/assets/svgExports/SearchIcon';
import { deviceWidth } from '@/utils/deviceWidth';
import { getStoreType } from '@/utils/getStoreType';
import { MAX_MOBILE_WIDTH, STORE_CUSTOMIZATION } from '@/utils/constants';
import { pushEventsLog, toggleRfqFormModal } from '@/redux/actions';
import { useSSRSelector } from '@/redux/ssrStore';
import { redirectToHomePage } from '@/utils/handleRedirectToHomePage';
import useStoreContactDetails from 'src/hooks/useStoreContactDetails';
import { getWhatAppRedirectionUrlHelp } from '@/utils/getWhatsAppRedirectionUrl';
import { isWebFrameRoute } from '../../Widgets2.0/Header/utils';

export const CommonHeaderIconsMobile = (props) => {
  const { storePhone } = useStoreContactDetails();
  const IconList = [
    {
      name: 'Search',
      imgSrc: '/assets/icons/search.svg',
      href: PATH.SEARCH,
      isVisible: true,
      customStyle: {
        filter: 'brightness(0)',
      },
    },
    {
      name: 'Contact Us',
      imgSrc: '/assets/icons/whatsapp.svg',
      href: false,
      isVisible: true,
    },
    {
      name: 'Cart',
      imgSrc: '/assets/icons/shopping-cart.svg',
      href: PATH.CART,
      isVisible: !isWebFrameRoute(),
    },
    {
      name: 'orders',
      imgSrc: '/assets/icons/user.svg',
      href: PATH.ORDERS,
      isVisible: true,
    },
  ];
  let IconListForHeaders = [];
  if (props?.headerName === 'header14') {
    if (props?.isWhatsappIconVisible)
      IconListForHeaders = [
        {
          name: 'Contact Us',
          svg: <WhatsappIcon color={props.color} width="25" height="25" />,
          href: false,
        },
      ];
  } else {
    IconListForHeaders = [
      {
        name: 'Search',
        svg: <SearchIcon color={props.color} width="25" height="25" />,
        href: PATH.SEARCH,
        isVisible: true,
        customStyle: {
          filter: 'brightness(0)',
        },
      },
      {
        name: 'Contact Us',
        svg: <WhatsappIcon color={props.color} width="25" height="25" />,
        href: false,
        isVisible: true,
      },
      {
        name: 'Cart',
        svg: <FilledShoppingCart color={props.color} width="25" height="25" />,
        href: PATH.CART,
        isVisible: !isWebFrameRoute(),
      },
      {
        name: 'orders',
        svg: <FilledUserIcon color={props.color} width="25" height="25" />,
        href: PATH.ORDERS,
        isVisible: true,
      },
    ];
  }

  const { items, userData } = useSelector((state) => ({
    items: state.cartReducer.items,
    userData: state.userReducer,
  }));

  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const dispatch = useDispatch();

  const iconClick = (href) => {
    const isB2bStore = getStoreType(storeData) === STORE_CUSTOMIZATION.B2B;
    if (href === PATH.CART && isB2bStore) {
      dispatch(toggleRfqFormModal());
      return;
    }
    if (storePhone) {
      dispatch(
        pushEventsLog({
          event_name: 'Cx_WA_Clicked',
          data: {
            store_id: storeData?.store_id,
            domain: storeData?.store_info?.domain,
            page: window.location.href,
            device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
            cx_number: userData?.data?.phone,
          },
        })
      );
    }
    if (href) {
      router.push(getRoute(href, storeData?.store_info?.domain));
    } else {
      window.open(getWhatAppRedirectionUrlHelp());
    }
  };

  function renderCartItemsQuantity(icon) {
    return (
      icon.name === 'Cart' &&
      items?.length > 0 && (
        <span
          className={`cart-item-length-comp absolute  ${
            props?.color === '#ffffff' ? 'filter-invert-1' : 'filter-invert-0'
          }`}
        >
          {items.length}
        </span>
      )
    );
  }
  function oldHeaderIcons() {
    return IconList.map((icon) => {
      if (!icon.isVisible) {
        return;
      }

      return (
        <span className="relative" key={icon.name}>
          <span className="pointer pr10px mobile-icons">
            <img
              src={icon.imgSrc}
              onClick={() => iconClick(icon.href)}
              style={icon.customStyle || {}}
            />
            {renderCartItemsQuantity(icon)}
          </span>
        </span>
      );
    });
  }

  function differentHeaderIcons() {
    return IconListForHeaders.map((icon) => {
      if (!icon.isVisible) {
        return;
      }

      return (
        <span className="flex relative" key={icon.name}>
          <span className="pointer pr10px mobile-icons">
            <span
              className="ml3 pl4px"
              onClick={() => iconClick(icon.href)}
              style={icon.customStyle || {}}
            >
              {icon.svg}
            </span>
            {renderCartItemsQuantity(icon)}
          </span>
        </span>
      );
    });
  }

  /**
   * @return {boolean} true for headers whose background color can be changeable
   */
  function isHeaderDifferent() {
    return (
      props?.headerName === 'header11' ||
      props?.headerName === 'header12' ||
      props?.headerName === 'header14'
    );
  }

  return (
    <div
      className={`flex items-center common-header-icons-mobile justify-end relative ${props.className}`}
    >
      {(props?.headerName === 'header14' ||
        (props?.headerName === 'header12' && !!props?.isStoreLogoVisible)) && (
        <img
          className={`${props?.headerName === 'header14' ? 'absolute l-35' : ''}
          ${storeData?.store_info?.logo_image ? 'logo_image' : 'dn'}`}
          src={storeData?.store_info?.logo_image}
          alt="store logo"
          onClick={() => redirectToHomePage(PATH.PRODUCT, storeData?.domain)}
        />
      )}
      {isHeaderDifferent() ? differentHeaderIcons() : oldHeaderIcons()}
    </div>
  );
};
